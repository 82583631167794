.searchPage_info{
    padding: 20px;
}

.searchPage_info>p{
    margin-bottom: 30px;
    font-size: 14px;
}

.searchPage_info>h1{
    margin-bottom: 30px;
}

.searchPage_info>button{
    border-radius: 30px;
    text-transform: inherit;
    margin: 5px;
}