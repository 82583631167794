.card_area_info{
    display: flex;
    padding: 30px;
}

.card_area_nearby{
    background-color: black;
    display: flex;
    padding: 30px;
}

.top_nearby{
    background-color: black;
    display: flex;
    flex-flow: column;
    padding: 30px;
}

.top_nearby > h1{
    padding-bottom: 10px;
    font-weight: 300;
    color: white;
}

.top_nearby > h4{
    font-weight: 300;
    color: white;
}