.card{
    margin: 10px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 6px 18px -9px rgba(0,0,0,0.75);
    transition: transform 100ms ease-in;
    cursor:pointer;
}

.card:hover{
    transform: scale(1.07);
}

.card >img{
    object-fit: fill;
    min-width: 300px;
    min-height: 200px;
    width: 100%;
    
}

.card_info{
    margin-top: -9px;
    border-radius: 10px;
    padding: 20px;
    padding-top: 20px;
    border: 1;
}

.card_info_text {
    color: white;
}

.card_info>h2{
    font-size: 18px;
    font-weight: 600;
}

.card_info>h4{
    font-size: 14px;
    font-weight: 300;
    margin-top: 8px;
    margin-bottom: 8px;
}

/* Black card - nearby highlights */

.card_black{
    margin: 10px;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 100ms ease-in;
    cursor:pointer;
    background-color: rgb(34, 34, 34) !important;
    height: 100%
}
.card_black:hover{
    transform: scale(1.04);
}

.card_black >img{
    object-fit: fill;
    height: auto;
    width: auto;
    max-width: 30vw;
}

.card_black_info{
    margin-top: -9px;
    border-radius: 10px;
    padding: 20px;
    padding-top: 20px;
    border: 1;
    color: white;
}

.card_black_info>h2{
    font-size: 18px;
    font-weight: 600;
    color: white;
}

.card_black_info>h4{
    font-size: 14px;
    font-weight: 300;
    margin-top: 8px;
    margin-bottom: 8px;
    color: white;
}