.dashboard > input {
    width: 539px;
    padding: 20px;
    position: absolute;
    left: 0;
    height: 30px;
    top: 420px;
    border: none;
}

.dashboard > input:focus{
    outline-width: 0px;
}