.banner {
    /* height: 50vh; */
    position: relative;
    /* https://substantial.com/uploads/_992x558_crop_center-center_100_line/Storytelling.jpg */
    background: url("https://media-exp1.licdn.com/dms/image/sync/C4D27AQHIbvZFcBbQ_Q/articleshare-shrink_1280_800/0?e=2159024400&v=beta&t=dvwiMIBtu7gNNMgylJVDrc210vzc4BhEZfbI-ze5l2c") center center;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;

}
.banner_info {
    background-color: black;
    color: white;
    padding-top: 15vh;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 148px;
    width: 300px;
}

.banner_info_text {
    color: white;
}

.banner_searchButton{
    background-color: white !important;
    font-weight: 900 !important;
    text-transform: inherit !important;
    color: #ff7779 !important;
}

.banner_search{
    display: flex;
    flex-direction: column;
}

.banner_info > button {
    background-color: #ff7779;
    color:white;
    text-transform: inherit;
    margin-top: 20px;
    font-weight: 600px;

}

.banner_info > button:hover {
    background-color: white;
    color: #ff7779;
    text-transform: inherit;

}

.banner_info > h5{
    margin-top: 10px;
}
