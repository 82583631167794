.searchResult{
    display: flex;
    position: relative;
    margin: 20px;
    padding:20px;
    border-bottom:1px solid lightgray;
    cursor: pointer;
}

.searchResult:hover{
    opacity: 0.8;
}

.searchResult > img{
    width: 350px;
    height: 200px;
    border-radius: 10px;
    overflow: hidden;

}

.searchResult_info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 20px;
}

.searchResult_heart{
    position: absolute;
    top: 20px;
    right:40px;
}

.searchResult_delete{
    position: absolute;
    top: 50px;
    right:40px;
}

.searchResult_info_top{
    /* width: 40vw; */
    top: 2px;
}

.searchResult_info_top >h3:hover{
    text-decoration: underline;
}
.searchResult_info_top >h3{
    font-weight: 300;
    margin-top: 10px;
}
.searchResult_info_top>p{
    margin-top: 10px;
    font-size: 13px;
    color: gray;
}

.searchResult.infoButtom{
    display: flex;
    justify-content: space-between;
}

.searchResult_stars{
    /* font-size: 20px; */
    display: flex;
    align-items: center;
    padding-bottom: 15 px;
    margin: 0px;
}

.searchResult_stars>p{
    margin-bottom: 0px;
    margin-top: 0px;
    margin-left: 4px;
    font-size: 13px;
    color: gray;
}

.searchResults_price {
    position: absolute;
    bottom: 20px;
    /* right: 30px; */
}